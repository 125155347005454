@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');



body, html, #root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #050505;
  
  font-family: 'Righteous', cursive;
  
  
}








/* Mobile portrait */
@media (max-width: 480px) {
/* CSS rules for mobile portrait here */

  body, html, #root {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #050505;
  }
  .landing {
 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #050505;
    color: #fff;
    font-family: 'Fredoka One', cursive;
    font-size: 12px;
    letter-spacing: 0.75px;
    text-align: center;
    padding: 0 20px;
    height: 100%;
    width: 100%;
    overflow: hidden;
  
  }

  h1,h2,h3{
    display: none;
  }
  
  button {
    display: none;
  }
  
  .rotate {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: rgb(255, 255, 255);
    width: 40%;
  
  }

  .title{
    
    display: none;
  
}

  .instruct {
    display: none;
  }

}

/* Mobile landscape  */
@media  (min-width: 480px) and (max-height: 500px) {
/* CSS rules for mobile landscape here */
.rotate {
  display: none;
 
 }

 .title{
   
     max-width: 75%; /* shrink the image to 50% of its original size */
     display: block;
     margin: 0 auto; /* center the image horizontally */
     position:absolute;
     top: 40%;
     
     transform: translateY(-50%);
     z-index: 1;
   
 }

 .instruct{
   position: absolute;
   top: 40%;
   left: 50%;
   max-width: 80%; /* the image will always cover 80% of the screen */
   max-height: 100%;
   transform: translate(-50%, -50%); /* center the image both horizontally and vertically */
 }

 .fortune{
   position: absolute;
   top: 50%;
   left: 50%;
   max-width: 80%; /* the image will always cover 80% of the screen */
   max-height: 100%;
   transform: translate(-50%, -50%); /* center the image both horizontally and vertically */

 }

 .landing {

   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background-color: #813333;
   color: #fff;
   font-family: 'Fredoka One', cursive;
   font-size: 20px;
   letter-spacing: 0.1px;
   text-align: center;
   padding: 0 20px;
   height: 100%;
   width: 100%;
   overflow: hidden;
   background: url('assets/bg-op.jpg') no-repeat center center fixed; 
 
 }

 .ins {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background-color: #813333;
   color: #fff;
   font-family: 'Fredoka One', cursive;
   font-size: 20px;
   letter-spacing: 0.1px;
   text-align: center;
   padding: 0 20px;
   height: 100%;
   width: 100%;
   overflow: hidden;
   background: url('assets/ins.jpg') no-repeat center center fixed;
 }

 .start{
 
   background: none;
   position: absolute;
   bottom: 20%;
   margin-left: auto;
   margin-right: auto;
  
   
   
   cursor: pointer;
   border: none;
   z-index: 20;
   
 
}

.start img{
  width: 50%;
  height: 50%;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 10px #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}


.steering-left {
 position: absolute;
 bottom: 0px;
 right: 20%;
 background-color: #85888C;
 opacity: 0.8;
 color: white;
 padding: 5px;
 width: 20%;
 height: 50%;
 float:left;
 background: none;
 
}

.steering-right {
 position: absolute;
 bottom: 0px;
 right: 0%;
 background-color: #85888C;
 opacity: 0.8;
 color: white;
 padding: 5px;
 width: 20%;
 height: 50%;
 float:left;
 background: none;
 
}

  .modal{
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #171718;
    border-radius: 20px;
    width: 130px;
    height: 250px;
    Display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 30px 50px 100px #38393a;
  }

  .modal-stand {
    position: absolute;
    top: 1;
    bottom: 1;
    width: 15px;
    height: 50%;
    background: linear-gradient(to top, #8b1f11, #ad0e0e);
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.75);
  }
  
  .modal-stand.left {
    left: -10px;
  }
  
  .modal-stand.right {
    right: -10px;
  }

  .modal-content {
    border: 1px solid #161718;
    border-radius: 20px;
    width: 94%;
    height: 95%;
    background-color: #dadada;
    overflow: hidden;
  }

 

  .modal.open {
    bottom: 1rem;
    left: 8rem;
    opacity: 1;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }

  .modal.closed {
    bottom: -200px;
    left: 6rem;
    opacity: 0;
    
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .front-cam {
    position: absolute;
    top: 5px;
    left:33%;
    
    
    width: 35%;
    height: 10%;
  }
}

/* Tablet portrait */
@media (min-width: 768px) and (max-width: 991px) and (min-height: 500px) {
/* CSS rules for tablet portrait */
body, html, #root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #050505;
}
.landing {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #050505;
  color: #fff;
  font-family: 'Fredoka One', cursive;
  font-size: 12px;
  letter-spacing: 0.75px;
  text-align: center;
  padding: 0 20px;
  height: 100%;
  width: 100%;
  overflow: hidden;

}

h1,h2,h3{
  display: none;
}

button {
  display: none;
}

.rotate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: rgb(255, 255, 255);
  width: 40%;

}

.title{
  
  display: none;

}

.instruct {
  display: none;
}
}

/* Tablet landscape and small desktop */
@media (min-width: 992px) and (max-width: 1199px) {
/* CSS rules for tablet landscape and small desktop */
.rotate {
  display: none;
 
 }

 .title{
   
     max-width: 75%; /* shrink the image to 50% of its original size */
     display: block;
     margin: 0 auto; /* center the image horizontally */
     position:absolute;
     top: 40%;
     
     transform: translateY(-50%);
     z-index: 1;
   
 }

 .instruct{
   position: absolute;
   top: 40%;
   left: 50%;
   max-width: 80%; /* the image will always cover 80% of the screen */
   max-height: 100%;
   transform: translate(-50%, -50%); /* center the image both horizontally and vertically */
 }

 .fortune{
   position: absolute;
   top: 50%;
   left: 50%;
   max-width: 80%; /* the image will always cover 80% of the screen */
   max-height: 100%;
   transform: translate(-50%, -50%); /* center the image both horizontally and vertically */

 }

 .landing {

   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background-color: #813333;
   color: #fff;
   font-family: 'Fredoka One', cursive;
   font-size: 20px;
   letter-spacing: 0.1px;
   text-align: center;
   padding: 0 20px;
   height: 100%;
   width: 100%;
   overflow: hidden;
   background: url('assets/bg-op.jpg') no-repeat center center fixed; 
 
 }

 .ins {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background-color: #813333;
   color: #fff;
   font-family: 'Fredoka One', cursive;
   font-size: 20px;
   letter-spacing: 0.1px;
   text-align: center;
   padding: 0 20px;
   height: 100%;
   width: 100%;
   overflow: hidden;
   background: url('assets/ins.jpg') no-repeat center center fixed;
 }

 .start{
 
   background: none;
   position: absolute;
   bottom: 20%;
   margin-left: auto;
   margin-right: auto;
   
   cursor: pointer;
   border: none;
   z-index: 20;
   
 
}

.start img {
    
    border-radius: 50%;
    border: 2px solid #fff;
  box-shadow: 0 0 10px #fff;
  cursor: pointer;
  transition: all 0.3s ease;
    
}

.steering-left {
 position: absolute;
 bottom: 0px;
 right: 20%;
 background-color: #85888C;
 opacity: 0.8;
 color: white;
 padding: 5px;
 width: 20%;
 height: 50%;
 float:left;
 background: none;
 
}

.steering-right {
 position: absolute;
 bottom: 0px;
 right: 0%;
 background-color: #85888C;
 opacity: 0.8;
 color: white;
 padding: 5px;
 width: 20%;
 height: 50%;
 float:left;
 background: none;
 
}

.modal {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #171718;
  border-radius: 20px;
  width: 220px;
  height: 400px;
  Display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 30px 50px 100px #38393a;
  
}

.modal-stand {
  position: absolute;
  top: 1;
  bottom: 1;
  width: 20px;
  height: 50%;
  background: linear-gradient(to top, #8b1f11, #ad0e0e);
  border-radius: 15px;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.75);
}

.modal-stand.left {
  left: -12px;
}

.modal-stand.right {
  right: -12px;
}

.modal-content {
  border: 1px solid #121213;
  border-radius: 15px;
  width: 94%;
  height: 95%;
  background-color: #dadada;
  overflow: hidden;

  
  
}




.modal {
  /* Other styles for the modal */
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.modal.open {
  bottom: 5rem;
  left: 10rem;
  opacity: 1;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.modal.closed {
  bottom: -200px;
  left: 15rem;
  opacity: 0;
}

.close-button {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.front-cam {
  position: absolute;
  top: 8px;
  left:35%;

  
  
  width: 35%;
  height: 10%;
  
  
}

}

/* Large desktop */
@media (min-width: 1200px) {
/* CSS rules for large desktop  */
.rotate {
  display: none;
 
 }

 .title{
   
     max-width: 75%; /* shrink the image to 50% of its original size */
     display: block;
     margin: 0 auto; /* center the image horizontally */
     position:absolute;
     top: 40%;
     
     transform: translateY(-50%);
     z-index: 1;
   
 }

 .instruct{
   position: absolute;
   top: 40%;
   left: 50%;
   max-width: 80%; /* the image will always cover 80% of the screen */
   max-height: 100%;
   transform: translate(-50%, -50%); /* center the image both horizontally and vertically */
 }

 .fortune{
   position: absolute;
   top: 50%;
   left: 50%;
   max-width: 80%; /* the image will always cover 80% of the screen */
   max-height: 100%;
   transform: translate(-50%, -50%); /* center the image both horizontally and vertically */

 }

 .landing {

   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background-color: #813333;
   color: #fff;
   font-family: 'Fredoka One', cursive;
   font-size: 20px;
   letter-spacing: 0.1px;
   text-align: center;
   padding: 0 20px;
   height: 100%;
   width: 100%;
   overflow: hidden;
   background: url('assets/bg-op.jpg') no-repeat center center fixed; 
 
 }

 .ins {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background-color: #813333;
   color: #fff;
   font-family: 'Fredoka One', cursive;
   font-size: 20px;
   letter-spacing: 0.1px;
   text-align: center;
   padding: 0 20px;
   height: 100%;
   width: 100%;
   overflow: hidden;
   background: url('assets/ins.jpg') no-repeat center center fixed;
 }

 .start{
 
   background: none;
   position: absolute;
   bottom: 20%;
   margin-left: auto;
   margin-right: auto;
   
   cursor: pointer;
   border: none;
   z-index: 20;
   
 
}

.start img {
    
  border-radius: 50%;
  border: 2px solid #fff;
box-shadow: 0 0 10px #fff;
cursor: pointer;
transition: all 0.3s ease;
  
}

.steering-left {
 position: absolute;
 bottom: 0px;
 right: 20%;
 background-color: #85888C;
 opacity: 0.8;
 color: white;
 padding: 5px;
 width: 20%;
 height: 50%;
 float:left;
 background: none;
 
}

.steering-right {
 position: absolute;
 bottom: 0px;
 right: 0%;
 background-color: #85888C;
 opacity: 0.8;
 color: white;
 padding: 5px;
 width: 20%;
 height: 50%;
 float:left;
 background: none;
 
}

.modal {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #171718;
  border-radius: 20px;
  width: 220px;
  height: 400px;
  Display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 30px 50px 100px #38393a;
  
}

.modal-stand {
  position: absolute;
  top: 1;
  bottom: 1;
  width: 20px;
  height: 50%;
  background: linear-gradient(to top, #8b1f11, #ad0e0e);
  border-radius: 15px;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.75);
}

.modal-stand.left {
  left: -12px;
}

.modal-stand.right {
  right: -12px;
}

.modal-content {
  border: 1px solid #121213;
  border-radius: 15px;
  width: 94%;
  height: 95%;
  background-color: #dadada;
  overflow: hidden;

  
  
}




.modal {
  /* Other styles for the modal */
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.modal.open {
  bottom: 10rem;
  left: 20rem;
  opacity: 1;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.modal.closed {
  bottom: -200px;
  left: 15rem;
  opacity: 0;
}

.close-button {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.front-cam {
  position: absolute;
  top: 8px;
  left:35%;

  
  
  width: 35%;
  height: 10%;
  
  
}

}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
 
    body, html, #root {
      margin: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: #050505;
    }
    .landing {
   
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #050505;
      color: #fff;
      font-family: 'Fredoka One', cursive;
      font-size: 12px;
      letter-spacing: 0.75px;
      text-align: center;
      padding: 0 20px;
      height: 100%;
      width: 100%;
      overflow: hidden;
      background: black
    
    }
  
    h1,h2,h3{
      display: none;
    }
    
    button {
      display: none;
    }
    
    .rotate {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: rgb(255, 255, 255);
      width: 40%;
      display: block;
    
    }
  
    .title{
      
      display: none;
    
  }
  
    .instruct {
      display: none;
    }
}




.controls {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: none;
  opacity: 0.9;
  color: white;
  padding: 5px;
  display: none;
  
}

.tools {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  opacity: 1;
  color: white;
  padding: 5px;
  display: none;
  
  
}

p {
  margin: 5px;
  font-family: monospace;
  font-size: 12px;
  letter-spacing: 0.75px;
}



.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: rgb(255, 255, 255);
  width: 20%;

}



.prize-enter, .message-enter, .lottie-enter {
  opacity: 0;
  
}

.prize-enter-active, .message-enter-active, .lottie-enter-active {
  opacity: 1;
  
  transition: opacity 1000ms ease-in, transform 300ms ease-in;
}

.prize-exit, .message-exit, .lottie-exit {
  opacity: 1;
}

.prize-exit-active, .message-exit-active, .lottie-exit-active {
  opacity: 0;
  
  transition: opacity 1000ms ease-out, transform 300ms ease-out;
}





.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in, transform 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-out, transform 300ms ease-out;
}






