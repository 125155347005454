@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap');


.App {
    text-align: center;
    display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Nunito', sans-serif;
  
  }

  
  .question-card {
    /* Center the div  */
    margin: 0 auto;
    
    /* center vertically */
    margin-top: 24px;
    
    

  
    /* Dimensions  */
    width: 75%; /* Can be in percentage also. */
    height: 40%;
  
    /* Visual  */
    background-color: rgb(37, 34, 34);
    padding: 16px;
    border-radius: 16px;
    color: rgb(26, 24, 24);
    background: none;
   
  }

  .landing-card {
    /* Center the div  */
    margin: 0 auto;
    
    /* center vertically */
    margin-top: 0px;
    
    

  
    /* Dimensions  */
    width: 85%; /* Can be in percentage also. */
    height: 40%;
  
    /* Visual  */
    background-color: rgb(42, 46, 87);
    padding: 16px;
   
    color: rgb(26, 24, 24);
    
    
   
  }

  .q-layout {
    background-color: rgba(207, 42, 42, 0.986);
    border: 2px solid rgb(207, 42, 42, 0.986);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    margin-top: 10px;
   
    
    
    
   
  }

  .q-layout::before {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgb(207, 42, 42, 0.986);
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }
  img.chat-img {
    position: absolute;
    top: -30px;
    right: -10px;
    width: 30px;
    height: 60px;
  }

  
  
  ul {
    list-style: none;
    padding: 0;

   
  }
  
  li {
    margin-top: 4px;
    background-color: rgba(24, 47, 175, 0.897);
    padding: 10px;
    border: 2px solid rgb(9, 12, 49);
    border-radius: 20px;
    font-size: 10px;
    font-weight: bold;
    color: rgb(252, 252, 252);
    
  }
  .question-text {
    color: rgb(228, 228, 228);
    font-size: 14px;
    margin: 3px;
   
    
  }
  
  .result-text {
    color: rgb(42, 37, 109);
    font-size: 14px;
    margin: 3px;
    font-family: 'Fjalla One', sans-serif;
  }
  
  /* Final Results  */
  
  .final-results {
    /* Center the div  */
    margin: 0 auto;
  
    /* Dimensions  */
    width: 60%; /* Can be in percentage also. */
    height: auto;
    margin-top: 64px;
  
    /* Visual  */
    background-color: rgb(207, 119, 119);
    padding: 16px;
    border-radius: 16px;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }

  .time{
    color: rgb(247, 247, 252);
    font-size: 60px;
    font-family: 'Londrina Outline', cursive;
    
  }

#score{
    margin: 0 0 5px 0;
    color: rgb(216, 19, 19);
}

#title{
    margin-bottom: 5px;
    color: rgb(216, 19, 19);
}

#question{
    margin: 5px;
}

.m-title{
  /* logo image  */
  width: 150px;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 20px;
}

@media only screen and (max-height: 500px) {
    .App {
        text-align: center;
        display: flex;
      justify-content: center;
      flex-direction: column;
      }

      .time{
        color: rgb(225, 225, 231);
        font-size: 22px;
        font-family: 'Londrina Outline', cursive;
      }
    
      
      .question-card {
        /* Center the div  */
        margin: 0 auto;
        
        /* center vertically */
        margin-top: 5px;
        
        
    
      
        /* Dimensions  */
        width: 75%; /* Can be in percentage also. */
        height: 40%;
      
        /* Visual  */
        background-color: rgb(37, 34, 34);
        padding: 16px;
        border-radius: 16px;
        color: rgb(26, 24, 24);
        background: none;
       
      }

      .landing-card {
        /* Center the div  */
    margin: 0 auto;
    
    /* center vertically */
    margin-top: 0px;
    
    

  
    /* Dimensions  */
    width: 75%; /* Can be in percentage also. */
    height: 100%;
  
    /* Visual  */
    background-color: rgb(42, 46, 87);
    padding: 16px;
   
    color: rgb(26, 24, 24);
       
      }

      .q-layout {
        background-color: rgba(207, 42, 42, 0.986);
        border: 2px solid rgb(207, 42, 42, 0.986);
        border-radius: 8px;
        padding: 5px;
        position: relative;
        margin-bottom: 1em;
        
       
        
        
        
       
      }
    
      .q-layout::before {
        content: "";
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid rgb(207, 42, 42, 0.986);
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
      
      img.chat-img {
        position: absolute;
        top: -20px;
        right: -5px;
        width: 20px;
        height: 40px;
      }
      

      .m-title{
        /* logo image  */
        width: 90px;
        height: 50px;
        margin: 0 auto;
        margin-bottom: 10px;
        margin-top: 40px;
      }
      
      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 2px;
        margin-top: 2px;
    
       
      }
      
      li {
        margin-top: 4px;
        background-color: rgba(48, 59, 124, 0.897);
        padding: 5px;
        border: 2px solid rgb(9, 12, 49);
        border-radius: 20px;
        font-size: 8px;
        color: rgb(252, 252, 252);
        
      }
      
      .question-text {
        color: rgb(255, 255, 255);
        font-size: 8px;
        margin: 2px;
      }

      .result-text {
        color: rgb(52, 53, 124);
        font-size: 8px;
        margin: 2px;
        font-family: 'Fjalla One', sans-serif;
      }
      
      /* Final Results  */
      
      .final-results {
        /* Center the div  */
        margin: 0 auto;
        font-size: 8px;
      
        /* Dimensions  */
        width: 80%; /* Can be in percentage also. */
        height: 40%;
        margin-top: 5px;
      
        /* Visual  */
        background-color: gray;
        padding: 4px;
        border-radius: 16px;
        color: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
          rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
      }
    
    #score{
        margin: 0 0 5px 0;
        color: rgb(216, 19, 19);
        font-size: 10px;
    }
    
    #title{
        margin-bottom: 2px;
        margin-top: 12px;
        color: rgb(216, 19, 19);
        font-size: 10px;
    }
    
    #question{
        margin: 2px;
    }

}
 
  
  