.FullPageImage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
    overflow: hidden;
  }

  .FullPageLottie{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
   
    z-index: 10;
    overflow: hidden;
    opacity: 0.3;
  }
  
  .center-img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
    max-height: 100%;
    z-index: 10;
    
  }
  